@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
._tWHtY {
  width: 110px;
  position: fixed;
  right: 1.2rem;
  bottom: 20px;
  cursor: pointer;
}

._MDaB7 {
  border-radius: 34px 8px 34px 34px;
  box-shadow: 0 5px 4px 0 rgb(0 0 0 / 26%);
  height: 50px;
  max-width: 140px;
  width: 100%;
  padding-right: .5rem;
  z-index: 9995;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;
  padding-left: .5rem;
}

._tWHtY img {
  width: 1.375rem;
  cursor: pointer;
  margin-right: .3rem;
}

._tWHtY label {
  margin-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  width: 80px;
  max-width: 80px;
  text-overflow: ellipsis;
  text-transform: capitalize;
  overflow: hidden;
  margin-right: -1rem;
}